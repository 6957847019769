<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">提示</div>
    </div>
    <div>以下人员已超过退休年龄，请确认是否继续？</div>
    <a-table :columns="columns" :data-source="tableData" row-key="phoneNumber">
    </a-table>
    <div style="text-align: center;width:100%;height: 50px;line-height: 50px;">
      <a-button @click="handleClose">{{ l("取消") }}</a-button>
      <a-button type="primary" @click="handleSubmit">{{ l("确认") }}</a-button>
    </div>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-project",
  data () {
    return {
      columns: [
        {
          title: this.l("姓名"),
          dataIndex: "realName",
          sorter: false,
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          width: 90,
          ellipsis: true,
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: this.l("身份证号"),
          dataIndex: "idCard",
          sorter: false,
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: this.l("性别"),
          dataIndex: "gender",
          sorter: false,
          align: "center",
          width: 70,
          ellipsis: true,
          scopedSlots: { customRender: "gender" },
        },
        {
          title: this.l("年龄"),
          dataIndex: "age",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 70,
          scopedSlots: { customRender: "age" },
        },
        {
          title: this.l("提示信息"),
          dataIndex: "tipInfo",
          sorter: false,
          align: "center",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "tipInfo" },
        },
      ],
      tableData: [],
      pagination: {},
      spinning: false
    }
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  methods: {
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    handleClose () {
      this.close();
    },
    handleSubmit () {
      this.success(true);
    }
  }
}
</script>