<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">盖章结果</div>
    </div>
    <div style="font-size: 15px;font-weight: bold;">
      总数：<span style="color:cornflowerblue">{{ tableData.length }}</span>条，
      成功：<span style="color:chartreuse">{{ successCount }}</span>条，
      失败：<span style="color:brown">{{ errorCount }}</span>条
      &nbsp;&nbsp;
      <a-button type="primary" @click="handleExport">导出数据</a-button>
    </div>
    <a-table :columns="columns" :data-source="tableData" row-key="phoneNumber">
    </a-table>
    <div style="text-align: center;width:100%;height: 50px;line-height: 50px;">
      <a-button @click="handleClose">{{ l("取消") }}</a-button>
      <a-button type="primary" @click="handleSubmit">{{ l("确认") }}</a-button>
    </div>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import * as XLSX from 'xlsx/xlsx.mjs';
export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-project",
  data () {
    return {
      columns: [
        {
          title: this.l("姓名"),
          dataIndex: "realName",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: '90px',
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          width: '90px',
          ellipsis: true,
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: this.l("身份证号"),
          dataIndex: "idCard",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: '110px',
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: this.l("合同编号"),
          dataIndex: "contractNo",
          sorter: false,
          align: "center",
          width: '120px',
          ellipsis: true,
          scopedSlots: { customRender: "contractNo" },
        },
        {
          title: this.l("结果"),
          dataIndex: "isSuccess",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: '70px',
          scopedSlots: { customRender: "isSuccess" },
          type: 'custom',
          customRender: (text) => {
            if (text) {
              return '成功';
            }
            return '失败';
          }
        },
        {
          title: this.l("失败原因"),
          dataIndex: "reason",
          sorter: false,
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "reason" },
        },
      ],
      tableData: [],
      pagination: {},
      spinning: false,
      successCount: 0,
      errorCount: 0
    }
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.successCount = this.tableData.filter(it => it.isSuccess).length;
    this.errorCount = this.tableData.filter(it => !it.isSuccess).length;
  },
  methods: {
    handleTableChange (pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    handleClose () {
      this.close();
    },
    handleSubmit () {
      this.success(true);
    },
    handleExport () {
      this.spinning = true;
      try {
        const data = [['姓名', '手机号', '身份证号', '合同编号', '盖章结果', '失败原因']];
        this.tableData.forEach(item => {
          data.push([
            item.realName,
            item.phoneNumber,
            item.idCard,
            item.contractNo,
            item.isSuccess ? '成功' : '失败',
            item.reason
          ]);
        });
        console.warn('XLSX', XLSX);
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        const ts = new Date().getTime();
        XLSX.utils.book_append_sheet(wb, ws, '盖章结果');
        XLSX.writeFile(wb, `${ts}盖章结果.xlsx`);
      } catch (e) {
        console.error(e);
        abp.message.error('导出异常，请稍后重试');
      } finally {
        this.spinning = false;
      }
    }
  }
}
</script>