import { ModalHelper } from "@/shared/helpers";
import AgeTipModal from './index.vue';

const shouldShowSealError = async (params, callback) => {
  ModalHelper.create(
    AgeTipModal,
    {
      ...params
    },
    {
      isChange: true,
      width: "1000px",
    }
  ).subscribe((res) => {
    callback(res);
  });
};

export default shouldShowSealError;