
import apiHttpClient from '@/shared/utils/api-http-client';
import { AppConsts } from '@/abpPro/AppConsts';
import { ModalHelper } from "@/shared/helpers";
import AgeTipModal from './index.vue';

const checkAgeFunc = async (params, callback) => {
  const res = await apiHttpClient.request({
    url: `${AppConsts.remoteServiceBaseUrl}/api/services/app/UnnaturalPersonProject/GetProjectPersonTipInfo`,
    method: "POST",
    data: params
  });
  const data = res?.data;
  if (data && data.showTipInfo) {
    ModalHelper.create(
      AgeTipModal,
      {
        tableData: data.infos,
        result: data,
        showAll: params.showAll
      },
      {
        isChange: true,
        width: "700px",
      }
    ).subscribe((res) => {
      callback(res);
    });
    return;
  }
  callback(true);
};

export default checkAgeFunc;